import React from "react"
import PropTypes from 'prop-types'
// You probably don't want to style this

const Address = ({ addresses, addressLabel, showPhone, showEmail, phoneLabel, emailLabel }) => {
  return (
    <ul className="address" style={{margin: 0, padding: 0, listStyle: "none"}}>
      {addresses.map((address, i) => {
        return (
        <li key={i}>
          { address.title && (
          <h4>{address.title}</h4>
          )}

          { addressLabel && (
          <span>{addressLabel}:</span>
          )}

          <address>
            {address.address1}
            { address.address2 && (
            <>
              <br/>
              {address.address2}
            </>
            )}
            { address.address3 && (
            <>
              <br/>
              {address.address3}
            </>
          )}
          </address>

          { showPhone && phoneLabel && address.phoneNumber && (
          <span className="telephone" style={{paddingRight: '8px'}}>{phoneLabel}:</span>
          )}
          { showPhone && address.phoneNumber && (
          <a href={'tel:' + address.phoneNumber} className="telephone">{address.phoneNumber}</a>
          )}
          { showEmail && (<>
          <br /><span className="email-contact" style={{paddingRight: '8px'}}>{emailLabel}:</span>
          <a href={'mailto:recepcja@mullenlowe.pl'} className="email-contact">recepcja@mullenlowe.pl</a>
          </>)}
        </li>
        )
      })}
    </ul>
  )
}

Address.defaultProps = {
  addressLabel: 'Adres',
  phoneLabel: 'Numer biura',
  emailLabel: 'Adres e-mail',
  showPhone: true,
  showEmail: true
}

Address.propTypes = {
  addresses: PropTypes.array.isRequired
}

export default Address
