import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactInfo from "../components/contactInfo"
import Description from "../components/description"
import Globe from "../components/globe/globe"
import PageData from "../components/pageData"
import Stats from "../components/stats"

import * as style from '../styles/page-contact.module.scss'
import Large2 from '../images/blobs/large2.inline.svg'

const PageContact = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout id="contact">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      <div className="page-header">
        <PageData {...page.PageData} />
        <div className="page-header-copy">
          <h1>{page.title}</h1>
          <Description {...page.Description} />
          <ContactInfo info={page.ContactInfo} />
        </div>
      </div>

      <Stats {...page.Stats} />

      <div className={style.globeWrap}>
        <div className={style.blobWrap}>
          <Large2 />
        </div>
        <Globe title="Odwiedź nas" fullDisplay={true} />
      </div>
    </Layout>
  )
}

export default PageContact

export const query = graphql`
  query($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      ContactInfo {
        mediaInquiries
        newBusiness
      }
      Description {
        description
      }
      PageData {
        megaNumber
        miniText
      }  
      Stats {
        stats {
          copy
          stat
          suffix
        }
      }
      SeoMeta {
        seoTitle
        seoDescription
      }
    }
  }`
  